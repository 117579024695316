<template>
  <sidebar-menu
    :menu="menu"
    :width="`${width}px`"
    @toggle-collapse="$emit('toggle-collapse', $event)"
    @item-click="onItemClick"
    :collapsed="collapsed"
  />
</template>

<script>
import firebase from "firebase";
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import SidebarLogoHeader from "./SidebarLogoHeader.vue";
export default {
  name: "SideBar",
  props: ["width", "collapsed"],
  components: {
    SidebarMenu,
  },
  data() {
    return {
      menu: [
        {
          component: SidebarLogoHeader,
        },
        {
          header: "Main Navigation",
          hiddenOnCollapse: true,
        },

        {
          href: "/Dashboard",
          title: "Dashboard",
          icon: "fa fa-university",
        },

        {
          title: "Relaties",
          icon: "fa fa-users",
          child: [
            {
              href: "/Klanten",
              title: "Klanten",
              icon: "fa fa-user ",
            },
            {
              href: "/Handelaars",
              title: "Handelaars",
              icon: "fa fa-user",
            },
          ],
        },

        {
          title: "Bestellingen",
          icon: "fa fa-shopping-cart",
          child: [
            {
              href: "/WebshopBestellingen",
              title: "Webshop Bestellingen",
              icon: "fa fa-shopping-cart",
            },

            // {
            //   href: "/EbayBestellingen",
            //   title: "Ebay Bestellingen",
            //   icon: "fa fa-shopping-bag",
            // },
          ],
        },

        {
          href: "/Kassa",
          title: "Kassa afrekenen",
          icon: "fa fa-shopping-basket",
        },

        {
          title: "Kavels",
          icon: "fa fa-file-image",
          child: [
            {
              href: "/Kavelsbeheren",
              title: "Kavels overzicht",
              icon: "fa fa-calendar",
            },
            {
              href: "/KavelToevoegen",
              title: "Kavel aanmaken",
              icon: "fa fa-plus-circle ",
            },
          ],
        },

        {
          href: "/Kortingenbeheren",
          title: "Kortingscode",
          icon: "fa fa-percent",
        },

        {
          href: "/Verzendkostenbeheren",
          title: "Verzendkostenbeheren",
          icon: "fa fa-shipping-fast",
        },

        // {
        //   href: "/Nieuwsbrieven",
        //   title: "Newsletters",
        //   icon: "fa fa-percent",
        // },

        {
          title: "Rapportages uitdraaien",
          icon: "fa fa-info-circle",
          child: [
            {
              href: "/RapportageUitdraaien",
              title: "Kavels rapportage uitdraaien",
              icon: "fa fa-database",
            },
            {
              href: "/HandelarenRapportage",
              title: "Handelaren rapportage uitdraaien",
              icon: "fa fa-database",
            },

            {
              href: "/WebshopRapportage",
              title: "Webshop rapportage uitdraaien",
              icon: "fa fa-database",
            },

            // {
            //   href: "/Ebayrapport",
            //   title: "Ebay rapportages",
            //   icon: "fa fa-file-invoice",
            // },
            {
              href: "/RaportTemplate",
              title: "Rapportage automatisch",
              icon: "fa fa-database",
            },
          ],
        },

        {
          title: "Rapportage Overzichten",
          icon: "fa fa-info-circle",
          child: [
            {
              href: "/ruudRapportage",
              title: "Ruud Overzicht",
              icon: "fa fa-tags",
            },

            {
              href: "/Handelaarkostenrapport",
              title: "Handelaarkosten overzicht",
              icon: "fa fa-file-invoice",
            },

            {
              href: "/HandelaarkostenrapportTabel",
              title: "Handelaarkosten Tabel-overzicht",
              icon: "fa fa-file-invoice",
            },

            {
              href: "/Rapportage",
              title: "Omzet overzicht",
              icon: "fa fa-file-invoice",
            },
            // {
            //   href: "/Ebayrapport",
            //   title: "Ebay rapportages",
            //   icon: "fa fa-file-invoice",
            // },
          ],
        },

        {
          title: "Betalingen in en uit",
          icon: "fa fa-archive",
          child: [
            {
              href: "/HandelaarBetalingenuit",
              title: "Handelaar in/uit betalingen",
              icon: "fa fa-briefcase",
            },

            {
              href: "/Betalingenuit",
              title: "Klanten in/uit betalingen",
              icon: "fa fa-tags",
            },
          ],
        },

        // {
        //   title: "Ebay instellingen",
        //   icon: "fa fa-cogs",
        //   child: [
        //     {
        //       href: "/EbayInstellingen",
        //       title: "Standaard Ebay teksten",
        //       icon: "fa fa-chevron-right",
        //     },
        //     {
        //       href: "/EbaySites",
        //       title: "Ebay sites beheren",
        //       icon: "fa fa-chevron-right",
        //     },
        //   ],
        // },

        {
          title: "Instructies",
          icon: "fa fa-info-circle",
          child: [
            {
              href: "/Instructies",
              title: "Instructie bekijken en aanmaken",
              icon: "fa fa-chevron-right",
            },
            {
              href: "/InstructiesCategorie",
              title: "Instructie categorie",
              icon: "fa fa-chevron-right",
            },
          ],
        },

        {
          title: "Instellingen",
          icon: "fa fa-cogs",
          child: [
            {
              href: "/Inhoud",
              title: "Kavel inhoud",
              icon: "fa fa-chevron-right",
            },
            {
              href: "/Account",
              title: "Account beheren",
              icon: "fa fa-user",
            },
            {
              href: "/Embcode",
              title: "Kavel Embcode",
              icon: "fa fa-chevron-right",
            },

            {
              href: "/BetaalStatus",
              title: "Betaalstatus bewerken",
              icon: "fa fa-chevron-right",
            },

            {
              href: "/OrderStatus",
              title: "Orderstatus bewerken",
              icon: "fa fa-chevron-right",
            },

            {
              href: "/Status",
              title: "Status bekijken en aanmaken",
              icon: "fa fa-chevron-right",
            },

            {
              href: "/LandToevegen",
              title: "Landen bewerken",
              icon: "fa fa-chevron-right",
            },
            {
              href: "/Continenten",
              title: "Continenten bekijken",
              icon: "fa fa-chevron-right",
            },

            {
              href: "/KlantenLog",
              title: "Klanten activiteiten",
              icon: "fa fa-envelope",
            },
            {
              href: "/Koopjes",
              title: "Koopjes vd dag",
              icon: "fa fa-tags",
            },

            {
              href: "/KavelsFolder",
              title: "Alle Folders bekijken",
              icon: "fa fa-file-invoice",
            },
          ],
        },

        {
          title: "Uitloggen",
          icon: "fa fa-power-off",
        },
        {
          href: "https://support.integratiepartners.nl",
          title: "Support",
          icon: "fa fa-life-ring",
          external: true,
        },
      ],
    };
  },
  methods: {
    async logOut() {
      await firebase.auth().signOut();
      location.reload();
    },
    onItemClick(event, item) {
      if (item.title === "Uitloggen") {
        this.logOut();
      } else if (item.external) {
        window.location.href = item.href;
      } else {
        this.$router.push(item.href);
      }
    },
  },
};
</script>
